import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { ThanksComponent } from './pages/thanks/thanks.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataService } from './shared/dataService';
import { UtilMethods } from './shared/utility-methods';
import { PricingWizardManagerService } from './pages/marketplace/marketplace.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PlansComponent } from './pages/plans/plans.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { GcpNotFoundComponent } from './pages/gcp-not-found/gcp-not-found.component';
import { NgxCaptchaModule } from 'ngx-captcha';
// import { FacenoteComponent } from './pages/facenote/facenote.component';
import { MatDialog, MatDialogModule } from '@angular/material';
import { ThankreturnComponent } from './pages/thankreturn/thankreturn.component';

@NgModule({
  declarations: [
    AppComponent,
    ThanksComponent,
    PlansComponent,
    FooterComponent,
    HeaderComponent,
    GcpNotFoundComponent,
    ThankreturnComponent,
    // FacenoteComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    // MarketPlaceModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    NgxUiLoaderModule,
    MatDialogModule,
    HttpClientModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    AppRoutingModule,
    NgxCaptchaModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy },
    DataService, UtilMethods, PricingWizardManagerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
