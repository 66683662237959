import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-terms-modal",
  templateUrl: "./terms-modal.component.html",
  styleUrls: ["./terms-modal.component.scss"],
})
export class TermsModalComponent {
  isScrolledToBottom = false;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<TermsModalComponent>,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
  }

  onScroll(event: any): void {
    const element = event.target;
    this.isScrolledToBottom =
      element.scrollHeight - element.scrollTop - 10 <= element.clientHeight; // 10 is the buffer
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onAccept(): void {
    if (!this.isScrolledToBottom) {
      this.toastr.warning(
        "Terms not fully reviewed. Please scroll to the bottom of Terms to continue.",
        "Error"
      );
      return;
    }
    this.dialogRef.close(true);
  }
}
