import { EventEmitter, Injectable } from "@angular/core";
import { Item, BusinessOptions, ObjectivesOptions } from "./constants/items";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { MessageService } from "../../shared/messageService";
import { Router, ActivatedRoute, Event } from "@angular/router";
import {
  map,
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from "rxjs/operators";
import {
  IMCartPlan,
  mCartPlan,
  mCartPlanGcp,
  Plans,
} from "./constants/mCartPlan";
import { PlatformLocation } from "@angular/common";
import { NavigationStart, Event as NavigationEvent } from "@angular/router";
import { filter } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

import * as queryString from "qs";

import { environment } from "src/environments/environment";
import { GoogleManagerService } from "src/app/google-manager.service";
import { DataService } from "src/app/shared/dataService";

interface Store {
  name: String;
  website: String | null;
  regions: Array<String> | null;
  directRelation: boolean | null;
}

interface Influencers {
  name: String;
  id: String;
}

@Injectable()
export class PricingWizardManagerService {
  public isLoadingDomainCheck: boolean = false;
  private businesstemsList: Item[];
  private objectivesOptions: Item[];
  public regex: any = {
    email: /^\w+([\.-]?\w+)+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
    password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
    website:
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
    tel: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/,
    domain: /^(?!-)[a-z0-9-]{3,}(?<!-)$/, // Added domain regex
  };
  public currentSelectedBusiness = null;
  public wizardSelectedPathIndex: number = 0;
  public wizardCurrStep: number = 0;
  public wizardPathArray: Array<Array<number>> = [
    [0, 1, 3, 4, 5, 6, 8], // Media, Procurement, Agency
    [0, 1, 2, 3, 4, 5, 6, 8], // CPG, others
    [0, 1, 2, 3, 4, 5, 6, 9, 8], // Malls, step 10 is extra step in malls category
  ];
  public marketPlaceCalculatorForm: FormGroup;
  public formRemoteData;
  public gcpData = null;
  // recaptcha will enable this
  public showQuoteBtn = false;
  public StepsStateInfo: BehaviorSubject<any>;
  selectedPlan: IMCartPlan = null;
  isFromGcp: boolean = false;
  currentPlans: IMCartPlan[] = [];
  Influencers: any;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private _fb: FormBuilder,
    private toastr: ToastrService,
    private googleManager: GoogleManagerService,
    private dataService: DataService
  ) {
    router.events
      .pipe(
        filter((event: NavigationEvent) => {
          return event instanceof NavigationStart;
        })
      )
      .subscribe((routerEvent: Event) => {
        this.routerEventsHandle(routerEvent, this);
      });

    this.initalizeSelectedPlan();
    this.initializeForm();
    // TODO: breaking
    /*this.checkForGcpJwtKey().subscribe(data => {
      const _data = data as any;
      if (_data && _data.params) {
        this.gcpData = _data.params;

        if (this.gcpData === '404') {
          // No plan was received, show error
          this.router.navigate(['/gcp-not-found']);
          return;
        }

        if (this.gcpData.plan) {
          this.isFromGcp = true;
          this.currentPlans = mCartPlanGcp;
          this.referredBy.setValue("GCP Marketplace");
          const selected_Plan = mCartPlanGcp.find(x => x.planId === this.gcpData.plan);
          if (selected_Plan) {
            this.selectedPlan = selected_Plan;
          } else {
            this.selectedPlan = mCartPlanGcp[0];
          }

          this.updatePlanTier(parseInt(this.selectedPlan.tier, 10));
        }
      }
    });*/
  }

  routerEventsHandle(event, ref) {
    if (event && event.url) {
      const query = event.url.split("?")[1];
      const params = queryString.parse(query);
      for (const [key, value] of Object.entries(params)) {
        if (key && key.trim().length > 0) {
          try {
            this.marketPlaceCalculatorForm.get(key).setValue(value);
          } catch (e) {}
        }
      }
      this.wizardCurrStep = parseInt(this.getStepNumber.value, 10);
    }
  }

  checkForGcpJwtKey() {
    return this.httpClient
      .get("/gcp-marketplace/get-key")
      .pipe(map((res) => res));
  }

  initalizeSelectedPlan() {
    if (this.isFromGcp) {
      this.currentPlans = mCartPlanGcp;
      this.selectedPlan = mCartPlanGcp[0]; // its value will be equals to selected plan
    } else {
      this.currentPlans = mCartPlan;
      this.selectedPlan = mCartPlan[0];
      this.updatePlanTier(parseInt(this.selectedPlan.tier, 10));
    }
  }

  updateSelectedPlan(plan) {
    this.selectedPlan = mCartPlan[plan];
    this.updatePlanTier(parseInt(this.selectedPlan.tier, 10));
  }

  updateSelectedGcpPlan() {
    let index = this.currentPlans.findIndex(
      (x) => x.planId === this.selectedPlan.planId
    );
    if (index >= 0 && index + 1 < this.currentPlans.length) {
      index++;
      this.selectedPlan = this.currentPlans[index];
      this.updatePlanTier(parseInt(this.selectedPlan.tier, 10));
      return true;
    } else {
      this.toastr.warning("No higher Plan");
      return false;
    }
  }

  resetStepData() {
    this.wizardCurrStep = 0;
    this.currentSelectedBusiness = null;
    this.wizardSelectedPathIndex = 0;
    this.showQuoteBtn = false;
    this.initializeForm();
  }

  getStateObservable(): Observable<any> {
    return this.StepsStateInfo.asObservable();
  }

  public get currentStateValue() {
    return this.StepsStateInfo.value;
  }

  initializeForm() {
    this.businesstemsList = BusinessOptions;
    this.objectivesOptions = ObjectivesOptions;

    this.formRemoteData = new BehaviorSubject({
      stores: [],
      categories: [],
      influencers: [],
    });

    this.marketPlaceCalculatorForm = this._fb.group({
      businessType: [this.businesstemsList[0].value, [Validators.required]],
      otherBusiness: new FormControl({ value: "", disabled: true }),
      objectives: [[] as Array<any>, [Validators.required]],
      salesChannel: [null as number, [Validators.required, Validators.min(1)]],
      globalChannel: [null as number, [Validators.required, Validators.min(1)]],
      approximateProfit: 0 as number,
      approximateLicenseFee: 0 as number,
      gmv: 10000 as number,
      mCart: 100 as number,
      platformCommissionPercentage: 10 as number,
      influencerPayoutPercentage: 30 as number,
      shopperRebatePercentage: 5 as number,
      selectedStores: [[] as Array<any>, Validators.required],
      suggestedStores: [] as Array<any>,
      selectedCategories: [[] as Array<any>, Validators.required],
      suggestedCategories: [] as Array<any>,
      influencers: [null, Validators.required],
      influencerMcarts: 0 as number,
      influencerFollowers: 0 as number,
      influencerMarketplaces: 0 as number,
      influencerInterest: [] as Array<any>,
      usersPlan: ["plan1", Validators.required],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      companyName: ["", Validators.required],
      teleNum: [
        "",
        [Validators.pattern(this.regex.tel), Validators.minLength(8)],
      ],
      email: ["", [Validators.required, Validators.email]],
      website: ["", [Validators.pattern(this.regex.website)]],
      referredBy: "" as String,
      comments: "" as String,
      options: [[], [Validators.required]],
      wallCount: [0, []],
      step_number: 0,
      wizardSelectedPathIndex: 0,
      request: "" as String,
      country: ["", Validators.required],
      domain: [
        "",
        [Validators.required, Validators.pattern(this.regex.domain)],
      ],
      acceptedTermsAndConditions: [false, Validators.requiredTrue],
      acceptedPrivacyPolicy: [false, Validators.requiredTrue],
    });

    let that = this;
    this.businessType.valueChanges.subscribe((item) => {
      this.businessTypeValueChanges(item);
      this.googleManager.reset();
      this.googleManager.changeState("businessType", item);
    });

    this.objectives.valueChanges.subscribe((item) => {
      const objectiveNames = [];
      for (const value of item) {
        const x = this.objectivesOptions.find((t) => t.value === value);
        if (x) {
          objectiveNames.push(x.name);
        }
      }
      this.googleManager.changeState("objectives", objectiveNames);
    });
    this.salesChannel.valueChanges.subscribe((item) => {
      this.googleManager.changeState("salesChannel", item);
    });
    this.globalChannel.valueChanges.subscribe((item) => {
      this.googleManager.changeState("globalChannel", item);
    });
    this.selectedStores.valueChanges.subscribe((item) => {
      const storeNames = [];
      this.dataService.getStores({}).subscribe((response: any) => {
        for (const id of item) {
          const store = response.find((x) => x.id == id);
          if (store) {
            storeNames.push(store.name);
            this.googleManager.changeState("stores", storeNames);
          }
        }
      });
    });

    this.domain.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((domain) => this.regex.domain.test(domain)),
        switchMap((domain) => {
          this.isLoadingDomainCheck = true;
          return this.dataService.checkSubDomain(domain);
        })
      )
      .subscribe((response) => {
        if (response.available === true) {
          this.domain.setErrors(null);
        } else {
          console.log("not available");
          this.domain.setErrors({ notAvailable: true });
          console.log(this.domain.errors);
        }
        this.isLoadingDomainCheck = false;
      });

    // this.selectedCategories.valueChanges.subscribe(item => {
    //   const names  = [];
    //   const tmp =  [
    //     { id: 855, name: 'Women', code: 'women' },
    //     { id: 854, name: 'Men', code: 'men' },
    //     { id: 853, name: 'Home & Living', code: 'living' },
    //     { id: 852, name: 'Kids', code: 'kids-and-baby' },
    //     { id: 919, name: 'Featured Carts', code: 'ep' }
    //   ];

    //   this.dataService.getCategoriesByParentCategory({}, tmp)
    //   .subscribe((response: any) => {
    //     const category = response.find(x => x.id === item);
    //     names.push(category.name);
    //     this.googleManager.changeState('categories', names);
    //     console.log(names);
    //   });
    // });
  }

  goToStep(step) {
    this.marketPlaceCalculatorForm.controls.step_number.setValue(
      Number(this.wizardCurrStep)
    );
    let params: any = this.marketPlaceCalculatorForm.value;
    if (step > 0) {
      this.googleManager.push();
    }
    switch (step) {
      case -1:
        window.location.href = "https://www.markit.place/for-enterprises";
        break;
      case 0:
        this.router.navigate(["marketplace/business"], {});
        break;
      case 1:
        this.router.navigate(["marketplace/objectives"], {
          //queryParams: params,
          //queryParamsHandling: 'merge'
        });
        break;
      case 2:
        this.router.navigate(["marketplace/sales"], {
          //queryParams: params,
          //queryParamsHandling: 'merge'
        });
        break;
      case 3:
        this.router.navigate(["marketplace/gmv"], {
          //queryParams: params,
          //queryParamsHandling: 'merge'
        });
        break;
      case 4:
        this.router.navigate(["marketplace/stores"], {
          //queryParams: params,
          //queryParamsHandling: 'merge'
        });
        break;
      case 5:
        this.router.navigate(["marketplace/categories"], {
          //queryParams: params,
          //queryParamsHandling: 'merge'
        });
        break;
      case 6:
        this.router.navigate(["marketplace/influencers"], {
          //queryParams: params,
          //queryParamsHandling: 'merge'
        });
        break;
      case 7:
        this.router.navigate(["marketplace/users"], {
          //queryParams: params,
          //queryParamsHandling: 'merge'
        });
        break;
      case 8:
        this.router.navigate(["marketplace/quote"], {
          //queryParams: params,
          //queryParamsHandling: 'merge'
        });
        break;
      case 9:
        this.router.navigate(["/marketplace/storeoptions"], {
          //queryParams: params,
          //queryParamsHandling: 'merge'
        });
        break;
      case 10:
        this.router.navigate(["marketplace/quote"], {
          //queryParams: params,
          //queryParamsHandling: 'merge'
        });
        break;
    }
    this.isCurrStepBeforeGMV();
  }

  goToNextStep() {
    const currArr = this.wizardPathArray[this.wizardSelectedPathIndex];
    this.wizardCurrStep++;
    this.goToStep(currArr[this.wizardCurrStep]);
    window.scrollTo(0, 250);
  }

  goToPrevStep() {
    const currArr = this.wizardPathArray[this.wizardSelectedPathIndex];
    this.wizardCurrStep--;
    this.goToStep(this.wizardCurrStep >= 0 ? currArr[this.wizardCurrStep] : -1);
    window.scrollTo(0, 250);
  }

  /*updateQueryParamsLive() {
    let params: any = this.marketPlaceCalculatorForm.value;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: params,
      queryParamsHandling: "merge",
      replaceUrl: true
    });
  }*/

  businessTypeValueChanges(value) {
    if (value == "other") {
      this.otherBusiness.setValidators(Validators.required);
      this.otherBusiness.enable();
    } else {
      this.otherBusiness.setValue("");
      this.otherBusiness.clearValidators();
      this.otherBusiness.disable();
    }
    // 0 - GMV Type 1
    // 1 - GMV Type 2
    // 2 - GMV Type 2 with extra option wizard
    switch (value) {
      case "media":
        this.wizardSelectedPathIndex = 0;
        break;
      case "cpg":
        this.wizardSelectedPathIndex = 1;
        break;
      case "malls":
        this.wizardSelectedPathIndex = 2;
        break;
      case "procurement":
        this.wizardSelectedPathIndex = 0;
        break;
      case "agency":
        this.wizardSelectedPathIndex = 0;
        break;
      default:
        this.wizardSelectedPathIndex = 1;
        break;
    }
    this.marketPlaceCalculatorForm.controls.wizardSelectedPathIndex.setValue(
      this.wizardSelectedPathIndex
    );
  }

  isCurrStepBeforeGMV() {
    const currArr = this.wizardPathArray[this.wizardSelectedPathIndex];
    let isBefore = false;
    switch (this.wizardSelectedPathIndex) {
      case 0:
        if (this.wizardCurrStep < 2) {
          isBefore = true;
        } else {
          isBefore = false;
        }
        break;
      case 1:
        if (this.wizardCurrStep < 3) {
          isBefore = true;
        } else {
          isBefore = false;
        }
        break;
      case 2:
        if (this.wizardCurrStep < 3) {
          isBefore = true;
        } else {
          isBefore = false;
        }
        break;
      default:
        isBefore = false;
        break;
    }
    this.messageService.sendMessage({
      type: "isCurrStepBeforeGMV",
      value: isBefore,
    });
  }

  get getStepNumber() {
    return this.marketPlaceCalculatorForm.get("step_number");
  }
  get getWizardSelectedPathIndex() {
    return this.marketPlaceCalculatorForm.get("wizardSelectedPathIndex");
  }
  get getBusinessItems() {
    return this.businesstemsList;
  }
  get getObjectivesOptions() {
    return this.objectivesOptions;
  }
  get businessType() {
    return this.marketPlaceCalculatorForm.get("businessType");
  }
  get otherBusiness() {
    return this.marketPlaceCalculatorForm.get("otherBusiness");
  }
  get objectives() {
    return this.marketPlaceCalculatorForm.get("objectives");
  }
  get salesChannel() {
    return this.marketPlaceCalculatorForm.get("salesChannel");
  }
  get globalChannel() {
    return this.marketPlaceCalculatorForm.get("globalChannel");
  }
  get approximateProfit() {
    return this.marketPlaceCalculatorForm.get("approximateProfit");
  }
  get approximateLicenseFee() {
    return this.marketPlaceCalculatorForm.get("approximateLicenseFee");
  }
  get gmv() {
    return this.marketPlaceCalculatorForm.get("gmv");
  }
  get mCart() {
    return this.marketPlaceCalculatorForm.get("mCart");
  }
  get platformCommissionPercentage() {
    return this.marketPlaceCalculatorForm.get("platformCommissionPercentage");
  }
  get influencerPayoutPercentage() {
    return this.marketPlaceCalculatorForm.get("influencerPayoutPercentage");
  }
  get shopperRebatePercentage() {
    return this.marketPlaceCalculatorForm.get("shopperRebatePercentage");
  }
  get selectedStores() {
    return this.marketPlaceCalculatorForm.get("selectedStores");
  }
  get suggestedStores() {
    return this.marketPlaceCalculatorForm.get("suggestedStores");
  }
  get selectedCategories() {
    return this.marketPlaceCalculatorForm.get("selectedCategories");
  }
  get suggestedCategories() {
    return this.marketPlaceCalculatorForm.get("suggestedCategories");
  }
  get influencers() {
    return this.marketPlaceCalculatorForm.get("influencers");
  }
  get influencerMcarts() {
    return this.marketPlaceCalculatorForm.get("influencerMcarts");
  }
  get influencerFollowers() {
    return this.marketPlaceCalculatorForm.get("influencerFollowers");
  }
  get influencerMarketplaces() {
    return this.marketPlaceCalculatorForm.get("influencerMarketplaces");
  }
  get influencerInterest() {
    return this.marketPlaceCalculatorForm.get("influencerInterest");
  }
  get usersPlan() {
    return this.marketPlaceCalculatorForm.get("usersPlan");
  }
  get storeOptions() {
    return this.marketPlaceCalculatorForm.get("options");
  }
  get wallCount() {
    return this.marketPlaceCalculatorForm.get("wallCount");
  }
  get firstName() {
    return this.marketPlaceCalculatorForm.get("firstName");
  }
  get lastName() {
    return this.marketPlaceCalculatorForm.get("lastName");
  }
  get companyName() {
    return this.marketPlaceCalculatorForm.get("companyName");
  }
  get teleNum() {
    return this.marketPlaceCalculatorForm.get("teleNum");
  }
  get website() {
    return this.marketPlaceCalculatorForm.get("website");
  }
  get referredBy() {
    return this.marketPlaceCalculatorForm.get("referredBy");
  }
  get comments() {
    return this.marketPlaceCalculatorForm.get("comments");
  }
  get email() {
    return this.marketPlaceCalculatorForm.get("email");
  }
  get request() {
    return this.marketPlaceCalculatorForm.get("request");
  }
  get country() {
    return this.marketPlaceCalculatorForm.get("country");
  }
  get domain() {
    return this.marketPlaceCalculatorForm.get("domain");
  }
  get acceptedTermsAndConditions() {
    return this.marketPlaceCalculatorForm.get("acceptedTermsAndConditions");
  }
  get acceptedPrivacyPolicy() {
    return this.marketPlaceCalculatorForm.get("acceptedPrivacyPolicy");
  }

  // mark all controls dirty
  markControlsTouched(group: any) {
    for (let i in group.controls) {
      group.controls[i].markAsTouched();
      if (
        group.controls[i] instanceof FormGroup ||
        group.controls[i] instanceof FormArray
      ) {
        this.markControlsTouched(group.controls[i]);
      }
    }
  }

  isBenefitTableOpen = false;
  isPlanInfoTableOpen = false;
  currPlanTier = 0;

  onBenefitTableOpenEvent = new EventEmitter<boolean>();
  onPlanInfoTableOpenEvent = new EventEmitter<boolean>();
  onScrollUpToFormEvent = new EventEmitter();
  onChangeSelectedBusiness = new EventEmitter<Item>();
  onUpdatePlanTier = new EventEmitter<number>();

  onObjectiveFormClearEvent = new EventEmitter();
  onGMVFormClearEvent = new EventEmitter();
  onStoreFormClearEvent = new EventEmitter();
  onCategoryFormClearEvent = new EventEmitter();
  onInfluencerFormClearEvent = new EventEmitter();
  onUserFormClearEvent = new EventEmitter();
  onSaleFormClearEvent = new EventEmitter();
  onQuoteFormClearEvent = new EventEmitter();

  openBenefitTable() {
    this.isBenefitTableOpen = true;
    this.onBenefitTableOpenEvent.emit(true);
  }

  openPlanInfoTable() {
    this.isPlanInfoTableOpen = true;
    this.onPlanInfoTableOpenEvent.emit(true);
  }

  scrollUpToForm() {
    this.onScrollUpToFormEvent.emit();
  }

  updatePlanTier(updatedTier: number) {
    this.currPlanTier = updatedTier;
    this.onUpdatePlanTier.emit(updatedTier);
  }

  clearObjective() {
    this.objectives.setValue("");
    this.onObjectiveFormClearEvent.emit();
  }

  clearSales() {
    this.salesChannel.setValue("0");
    this.globalChannel.setValue("0");
  }
  clearGMV() {
    this.gmv.setValue("");
    this.platformCommissionPercentage.setValue("");
    this.influencerPayoutPercentage.setValue("");
    this.shopperRebatePercentage.setValue("");
    this.onGMVFormClearEvent.emit();
  }
  changeSelectedBusiness(selectedBusiness: Item) {
    this.currentSelectedBusiness = selectedBusiness;
    this.onChangeSelectedBusiness.emit(selectedBusiness);
  }
  clearStore() {
    this.selectedStores.setValue([]);
    this.onStoreFormClearEvent.emit();
  }
  clearCategory() {
    this.selectedCategories.setValue([]);
    this.onCategoryFormClearEvent.emit();
  }
  clearInfluencers() {
    this.influencers.setValue([]);
    this.influencerMcarts.setValue("");
    this.influencerFollowers.setValue("");
    this.influencerMarketplaces.setValue("");
    this.influencerInterest.setValue("");
    this.onInfluencerFormClearEvent.emit();
  }
  clearNumberOfUsers() {
    this.usersPlan.setValue("plan1");
    this.onUserFormClearEvent.emit();
  }
  clearRequestQuote() {
    this.firstName.setValue("");
    this.lastName.setValue("");
    this.companyName.setValue("");
    this.teleNum.setValue("");
    this.email.setValue("");
    this.website.setValue("");
    this.referredBy.setValue("");
    this.comments.setValue("");
  }
  clearStoreOptions() {}

  getFinalDataBody() {
    let jsonData = this.marketPlaceCalculatorForm.getRawValue();
    if (this.businessType.value === "malls") {
      let obj = jsonData;
      obj.squareFootage = obj.salesChannel;
      obj.salesPerSquareFoot = obj.globalChannel;
      delete obj.salesChannel;
      delete obj.globalChannel;
      jsonData = obj;
    }

    if (jsonData && jsonData.objectives) {
      let objectives = [];
      if (typeof jsonData.objectives === "string") {
        objectives.push(jsonData.objectives);
      } else {
        jsonData.objectives.forEach((element) => {
          const item = this.objectivesOptions.find((x) => x.value === element);
          objectives.push(item.name);
        });
      }
      jsonData.objectives = objectives;
    }

    if (this.isFromGcp) {
      jsonData = { ...jsonData, isFromGcp: true, gcpData: this.gcpData };
      this.referredBy.setValue("GCP Marketplace");
    }

    const body = {
      applicationData: {
        firstName: this.firstName.value,
        lastName: this.lastName.value,
        email: this.email.value,
        phone: this.teleNum.value,
        website: this.website.value,
        referred_by: this.referredBy.value,
        comments: this.comments.value,
        status: "new",
        selections: jsonData,
        source: window.location.href,
        gender: "",
        zip: "",
        company: this.companyName.value,
        type: "markitplace",
        country: this.country.value,
        domain: this.domain.value,
        acceptedTermsAndConditions: this.acceptedTermsAndConditions.value,
        acceptedPrivacyPolicy: this.acceptedPrivacyPolicy.value
      },
    };
    return body;
  }

  emailPlanDetails() {
    const body = this.getFinalDataBody();

    return this.httpClient.post(
      "https://staging.admin.buybit.io/api/sendEmail",
      {
        body,
        email: "support@mcart.io",
      }
    );
  }

  postPlanDetails() {
    const body = this.getFinalDataBody();
    const url = `${environment._api_base_url}/prospect-journey-applications`;

    return this.httpClient.post(url, body).pipe(
      map((data: Response) => {
        this.resetStepData();
        this.router.navigate(["/thanks"], {
          queryParams: { id: "marketplace" },
        });
      }),
      catchError((err) => {
        console.log(err.error.message);
        this.toastr.warning(
          "You have already applied for this role with same email"
        );
        throw err;
      })
    );
  }
}
